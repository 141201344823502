import config from "utils/config";

const SocialLoginConstants = {
  // User
  LINKEDIN_CLIENT_ID: `${config.LINKEDIN_CLIENT_ID}`,
  LINKEDIN_CLIENT_SECRET: "bFDJCQSKf4icVwsD",
  LINKEDIN_REDIRECT_URL: `${config.BASE_URL}auth/linkedin`,
  TOPBOT_LINKEDIN_REDIRECT_URL: `${config.BASE_URL}auth/linkedin_topbot`,
  LINKEDIN_LOGIN_REDIRECT_URL: `${config.BASE_URL}auth/linkedinlogin`,
  TWITTER_LOGIN_REDIRECT_URL: `${config.BASE_URL}auth/twitter`,
  LINKEDIN_LOGIN_CLIENT_ID: "772qovnhxi14dl",

  GOOGLE_CLIENT_ID:
    "225410318061-bqpcs5mfud453nb16ib992tiea9d8alb.apps.googleusercontent.com",
  GOOGLE_CLIENT_SECRET: "5u8TTt7Km7LCnY5wux_fAkVd",
  GOOGLE_REDIRECT_URL: `${config.BASE_URL}auth/google`,
  TOPBOT_GOOGLE_REDIRECT_URL: `${config.BASE_URL}auth/google_topbot`,
  FOLLOWER_GOOGLE_REDIRECT_URL: `${config.BASE_URL}auth/googlefollower`,
  GOOGLE_SCOPE: "profile email",
  TWITTER_AUTOLIKE_CLIENT_ID: "N3BrcWRkcE9PYXY3V2ZVUFdJTFk6MTpjaQ",
  TWITTER_AUTOLIKE_REDIRECT: `${config.BASE_URL}auth/autoliketwitter`,

  MICROSOFT_AUTH:
    "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=88982fb9-1c09-4cd3-8f3c-cad6333ef81d&response_type=code&redirect_uri=https%3A%2F%2Ftopmate.io%2Fauth%2Foutlook&response_mode=query&scope=offline_access%20Calendars.ReadWrite%20Calendars.Read%20email%20profile%20openid%20User.Read&state=12345",
};

export default SocialLoginConstants;
