import { GoogleOAuthProvider } from "@react-oauth/google";
import Featured from "components/PublicPages/Featured/Featured";
import HomePage from "components/PublicPages/Homepage/Homepage";
import { NextPage } from "next";
import SocialLoginConstants from "services/constants/SocialLoginConstants";
import config from "utils/config";

const isWhitelabel = config?.IS_WHITELABEL === "true" ? true : false;

const HomepageComponent: NextPage = () => (
  <GoogleOAuthProvider clientId={SocialLoginConstants.GOOGLE_CLIENT_ID}>
    {isWhitelabel ? <Featured /> : <HomePage />}
  </GoogleOAuthProvider>
);

// export async function getServerSideProps() {
//   try {
//     const response: any = getCountry();
//     const jsonData: any = await response?.json();
//     return {
//       props: {
//         jsonData,
//       },
//     };
//   } catch (error) {
//     console.error("Error Fething Data", error);
//     return {
//       props: {
//         country: "IN",
//       },
//     };
//   }
// }

export default HomepageComponent;
